import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { tooltipHeaderVampireBackground } from 'Images';

interface IStyleProps {
  elementTagColor?: string;
  elementThumbnail?: string | null;
  activationUnitIcon?: string | null;
}

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    height: '4rem',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.625rem',
    height: '4rem',
    borderRadius: '.5rem .5rem 0 0',
    zIndex: 200,
  },
  headerBackground: {
    position: 'absolute',
    height: 'inherit',
    width: '100%',
    background: `url(${tooltipHeaderVampireBackground.src}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: '.5rem .5rem 0 0',
    zIndex: 100,
  },
  elementThumbnail: {
    height: '32px',
    width: '33px',
    marginRight: '1rem',
    borderRadius: '.25rem',
    overflow: 'hidden',
    zIndex: 'inherit',
  },
  lockedElementThumbnail: {
    height: '32px',
    width: '33px',
    marginRight: '1rem',
    borderRadius: '.25rem',
    overflow: 'hidden',
    zIndex: 'inherit',
    filter: 'brightness(50%)',
  },
  lockIcon: {
    position: 'absolute',
    zIndex: 1000,
    left: '.925rem',
    top: '1.3rem',
    filter: 'brightness(200%)',
  },
  lockedContainer: {},
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 'inherit',
  },
  elementNameContainer: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 'inherit',
  },
  elementName: {
    fontFamily: 'goodOTCondBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '100%',
    color: theme.palette.background.paper,
    zIndex: 'inherit',
    textTransform: 'uppercase',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    zIndex: 'inherit',
  },
  elementTagContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '.5rem',
    zIndex: 'inherit',
  },
  elementTag: {
    background: (props: IStyleProps) =>
      !!props.elementTagColor ? props.elementTagColor : '#C82434',
  },
}));
