import React from 'react';

interface IThreeActionIconProps {
  fillColor?: string;
  width?: string;
  height?: string;
}

export const SingleActionIcon = ({
  fillColor = '#687079',
  height = '24',
  width = '24',
}: IThreeActionIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Single Action'
    >
      <title id='single-action-icon'>Single Action</title>
      <path
        d='M6.04967 18.1197L12.3133 11.9286L6.06401 5.77128L11.8716 0L24.0002 12.0405C19.9968 16.0129 15.9349 20.0436 11.9477 24C10.0966 22.1544 8.14513 20.2088 6.04967 18.1197Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M4.04968 16.1486L0 12.1139L4.10918 7.99998L8.16093 12.0511L4.04968 16.1486Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
    </svg>
  );
};
