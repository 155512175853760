export const validateProps = (props: any, keys: string[]) => {
  let valid = true;

  keys.forEach((k) => {
    if (!(k in props)) {
      valid = false;
    }
  });

  return valid;
};
