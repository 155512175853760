import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { tooltipHeaderBackground } from 'Images';

interface IStyleProps {
  isCard: boolean;
  overflowing: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  toolTip: {
    width: (props: IStyleProps) => (props.isCard ? '19rem' : '47.625rem'),
    maxHeight: '34.625rem',
    background: '#202020',
    borderRadius: '.5rem',
    color: theme.palette.background.paper,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1102,
  },
  elementDisplayContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0 0',
  },
  readMoreFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '4rem',
    left: 0,
    bottom: 0,
    width: '100%',
    background: '#202020',
    borderRadius: '0 0 .5rem .5rem',
  },
  readMoreContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  readMore: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#8D8D8D',
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: '2rem',
  },
  loadingText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: 0,
    marginLeft: '1rem',
    marginRight: '1rem',
    marginTop: 0,
    marginBottom: '2rem',
  },
  loadingContainerHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    padding: 0,
    height: '4rem',
    marginBottom: 0,
    // background: `linear-gradient(184.27deg, rgba(0, 0, 0, 0.8) 54.22%, rgba(0, 0, 0, 0) 105.23%), url(${tooltipHeaderBackground.src});`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'left 10% bottom 50%',
    // filter: 'saturate(0) brightness(2.5);',
  },
  loadingHeaderBackground: {
    background: `linear-gradient(184.27deg, rgba(0, 0, 0, 0.8) 54.22%, rgba(0, 0, 0, 0) 105.23%), url(${tooltipHeaderBackground.src});`,
    backgroundSize: 'cover',
    backgroundPosition: 'left 10% bottom 50%',
    filter: 'saturate(0) brightness(2.5);',
    height: 'inherit',
    width: '100%',
  },
  text: {
    padding: 0,
    margin: 0,
  },
  constructionTooltip: {
    maxWidth: 762,
    background: '#202020',
    borderRadius: '.5rem',
    color: theme.palette.background.paper,
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1102,
    padding: 0,
  },
}));
