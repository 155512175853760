import React from 'react';

interface IThreeActionIconProps {
  fillColor?: string;
  width?: string;
  height?: string;
}

export const ThreeActionIcon = ({
  fillColor,
  height = '24',
  width = '51',
}: IThreeActionIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 51 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Three Actions'
    >
      <title id='three-action-icon'>Three Actions</title>
      <path
        d='M6.94164 18.161C8.9843 16.1349 11.0581 14.0778 13.1632 11.9899L6.95014 5.78554C8.945 3.79724 10.8799 1.86872 12.7548 0L24.7641 12.0128C20.8084 15.969 16.7704 20.0075 12.7783 24C10.8984 22.1193 8.98286 20.203 6.94164 18.161Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M28.2934 1.26009C31.8131 4.77999 35.3322 8.30084 38.7781 11.7485C35.28 15.2464 31.7422 18.784 28.2367 22.2892L23.2756 17.3416L28.7454 12.0553L23.1278 6.43428C24.9361 4.62312 26.6144 2.94209 28.2934 1.26009Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M42.5751 3.42262L50.8318 11.6855C48.0903 14.4258 45.2704 17.2444 42.527 19.9865C41.371 18.813 40.0333 17.4553 38.6356 16.0366C39.9301 14.7561 41.3961 13.3059 42.8482 11.8695L38.5653 7.43439L42.5751 3.42262Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M9.16247 11.9777L5.00303 16.1319L0.834961 11.9555L5.0084 7.78554L9.16247 11.9777Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
    </svg>
  );
};
