import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { NexusTraits } from 'Components/NexusTraits';

interface IElementDisplayHeaderMutantProps {
  tagBackgroundColor?: string | undefined | null;
  elementCategory?: string | undefined | null;
  elementSlug?: string | undefined | null;
  nexusConfigData?: string | undefined | null;
  traits?: string | undefined | null;
  name?: string | undefined | null;
  tagTitle?: string | undefined | null;
  type: string;
}

const ElementDisplayHeaderMutantComp = ({
  tagBackgroundColor,
  elementCategory,
  elementSlug,
  nexusConfigData,
  traits,
  name,
  tagTitle,
  type,
}: IElementDisplayHeaderMutantProps) => {
  const classes = useStyles({
    elementTagColor: tagBackgroundColor ?? undefined,
    type,
  });
  const [traitNames, setTraitNames] = useState<any[]>([]);
  const [traitColors, setTraitColors] = useState<any>();
  const [configData, setConfigData] = useState<any>();

  useEffect(() => {
    if (!!nexusConfigData) {
      setConfigData(nexusConfigData);
    }

    let traitsArray: string[] = [];
    if (!!traits) {
      traitsArray = traits.split(',');
    }
    let names: string[] = [];
    let colors: { [key: string]: string } = {};
    let items: string[] = [];

    traitsArray.forEach((trait) => {
      items = trait.split('|');
      names.push(items[0]);
      colors[items[0]] = items[1];
    });

    setTraitNames(names);
    setTraitColors(colors);
  }, [nexusConfigData, traits]);

  return (
    <Box
      className={`${classes.header} element-display-header`}
      data-cy='element-display-header'
    >
      <Box className={`${classes.topRow} element-display-header-top-row`}>
        <Box
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Box
            className={`${classes.separator} element-display-header-top-separator`}
          />
          <Box
            className={`${classes.topRowInner} element-display-header-top-row-inner`}
          >
            <Box
              className={`${classes.elementName} elem-disp-header-name-${type}`}
            >
              <h1>{name}</h1>
            </Box>
            <Box
              className={`${classes.elementTag} element-display-header-tag element-display-header-tag-${elementCategory} element-display-header-tag-${elementSlug}`}
            >
              {tagTitle}
            </Box>
          </Box>
          <Box
            className={`${classes.separator} element-display-header-bottom-separator`}
          />
        </Box>
      </Box>
      <Box className={`${classes.bottomRow} element-display-header-bottom-row`}>
        <Box
          className={`${classes.traitsContainer} element-display-header-traits-container`}
        >
          {!!traits && !!configData && (
            <NexusTraits
              traits={traitNames}
              traitColors={traitColors}
              configData={{
                primary: {
                  font: configData.primary.font,
                  color: '#FDFDFD',
                },
                nexusId: configData.nexus_id,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ElementDisplayHeaderMutant = ElementDisplayHeaderMutantComp;
