import React from 'react';
import Image from 'next/legacy/image';
import { useStyles } from './styles';
import { useUserEntitlementsQuery } from 'Graphql/user-entitlements.query.generated';
import { LockedIcon } from 'Icons/LockedIcon';
import { useRouter } from 'next/router';
import { ElementToolTipDataPrimerVersion } from 'Graphql/types';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
import { useCurrentUser } from 'Hooks/CurrentUser';

interface IElementToolTipHeaderVampireProps {
  primerVersion: ElementToolTipDataPrimerVersion;
}

const PrimerToolTipHeaderVampireComp = ({
  primerVersion,
}: IElementToolTipHeaderVampireProps) => {
  const { currentUser } = useCurrentUser();
  const router = useRouter();
  const { nexusSlug } = router.query;

  const classes = useStyles({
    elementTagColor: undefined,
    elementThumbnail: `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${primerVersion.element_thumbnail}`,
  });

  const primerId = primerVersion.primer.id;

  const [entitlementResult] = useUserEntitlementsQuery({
    variables: {
      category: primerVersion.primer.category + '_display',
      ids: [primerId ?? ''],
    },
    pause: !currentUser || !primerId,
    requestPolicy: 'network-only',
  });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  let entitled = false;

  if (
    (!entitlementDataLoading &&
      !!entitlementData?.slsUserEntitlements?.data &&
      entitlementData.slsUserEntitlements.data.length === 1 &&
      entitlementData.slsUserEntitlements.data[0].owned.userStatus) ||
    (!!nexusSlug && nexusSlug === 'pathfinder2e')
  ) {
    entitled = true;
  }

  const elementThumbnailImage = !!primerVersion.element_thumbnail
    ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${primerVersion.element_thumbnail}`
    : undefined;

  if (entitlementDataLoading) {
    return null;
  }

  return (
    <div className={classes.header}>
      <div className={classes.headerBackground}></div>
      <div className={classes.headerContent}>
        {elementThumbnailImage && (
          <>
            {entitled ? (
              <div className={classes.elementThumbnail}>
                <Image
                  loader={imgOpLoader}
                  height={32}
                  width={33}
                  src={elementThumbnailImage}
                  alt={primerVersion.name}
                  layout='fixed'
                  quality={100}
                  unoptimized={true}
                />
              </div>
            ) : (
              <>
                <div className={classes.lockIcon}>
                  <LockedIcon width='22' height='22' fill='#FDFDFD' />
                </div>
                <div className={classes.lockedElementThumbnail}>
                  <Image
                    loader={imgOpLoader}
                    height={32}
                    width={33}
                    src={elementThumbnailImage}
                    alt={primerVersion.name}
                    layout='fixed'
                    quality={100}
                  />
                </div>
              </>
            )}
          </>
        )}
        <div className={classes.detailsContainer}>
          <div className={classes.elementNameContainer}>
            <div className={`tooltip-header-name`}>{primerVersion.name}</div>
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.elementTagContainer}>
            <div
              className={`${classes.elementTag} tooltip-header-tag tooltip-header-tag-${primerVersion.primer.category} tooltip-header-tag-${primerVersion.primer.slug}`}
            >
              {'primers dont have tag_title...'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PrimerToolTipHeaderVampire = PrimerToolTipHeaderVampireComp;
