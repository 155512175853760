import React, { useState, useEffect } from 'react';
import Image from 'next/legacy/image';
import { useStyles } from './styles';
import { NexusTraits } from 'Components/NexusTraits';
import { useUserEntitlementsQuery } from 'Graphql/user-entitlements.query.generated';
import { LockedIcon } from 'Icons/LockedIcon';
import { ElementToolTipDataPrimerVersion } from 'Graphql/types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
import { useCurrentUser } from 'Hooks/CurrentUser';

interface IElementToolTipHeaderBaseProps {
  primerVersion: ElementToolTipDataPrimerVersion;
}

const PrimerToolTipHeaderBaseComp = ({
  primerVersion,
}: IElementToolTipHeaderBaseProps) => {
  const theme = useTheme();
  const { currentUser } = useCurrentUser();
  const classes = useStyles({
    elementThumbnail: `${primerVersion.element_thumbnail}`,
  });
  const [traitNames, setTraitNames] = useState<any[]>([]);
  const [traitColors, setTraitColors] = useState<any>();
  const [configData, setConfigData] = useState<any>();
  const [themeConfig, setThemeConfig] = useState<any>();
  const [nexusId, setNexusId] = useState<string>('');

  useEffect(() => {
    if (!!primerVersion && !!primerVersion.primer.nexus) {
      setConfigData(primerVersion.primer.nexus.config_data);
    }

    if (!!configData && !!configData.theme) {
      setThemeConfig(configData.theme);
    }

    setNexusId(primerVersion.primer.nexus.id);

    let traitsArray: string[] = [];
    if (!!primerVersion.traits) {
      traitsArray = primerVersion.traits.split(',');
    }
    let names: string[] = [];
    let colors: { [key: string]: string } = {};
    let items: string[] = [];

    traitsArray.forEach((trait) => {
      items = trait.split('|');
      names.push(items[0]);
      colors[items[0]] = items[1];
    });

    setTraitNames(names);
    setTraitColors(colors);
  }, [primerVersion, primerVersion.traits, configData]);

  const primerId = primerVersion.primer.id;

  const [entitlementResult] = useUserEntitlementsQuery({
    variables: {
      category: primerVersion.primer.category + '_display',
      ids: [primerId ?? ''],
    },
    pause: !currentUser || !primerId,
    requestPolicy: 'network-only',
  });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  let entitled = false;

  if (
    (!entitlementDataLoading &&
      !!entitlementData?.slsUserEntitlements?.data &&
      entitlementData.slsUserEntitlements.data.length === 1 &&
      entitlementData.slsUserEntitlements.data[0].owned.userStatus) ||
    primerVersion.primer.nexus.slug === 'pathfinder2e'
  ) {
    entitled = true;
  }

  return (
    <Box
      className={`${classes.header} element-tooltip-header-outer`}
      sx={{
        background: !!themeConfig
          ? themeConfig.palette.base.base3
          : theme.palette.base.base3,
      }}
    >
      <Box
        className={`${classes.headerBackground} element-tooltp-header-background`}
      ></Box>
      <Box className={`${classes.headerContent} element-tooltip-header-inner`}>
        {primerVersion.element_thumbnail && (
          <>
            {entitled ? (
              <Box className={classes.elementThumbnail}>
                <Image
                  loader={imgOpLoader}
                  height={60}
                  width={60}
                  src={primerVersion.element_thumbnail}
                  alt={primerVersion.name}
                  layout='fixed'
                  quality={100}
                  unoptimized={true}
                />
              </Box>
            ) : (
              <>
                <Box className={classes.lockIcon}>
                  <LockedIcon width='24' height='24' fill='#FDFDFD' />
                </Box>
                <Box className={classes.lockedElementThumbnail}>
                  <Image
                    loader={imgOpLoader}
                    height={60}
                    width={60}
                    src={primerVersion.element_thumbnail}
                    alt={primerVersion.name}
                    layout='fixed'
                    quality={100}
                  />
                </Box>
              </>
            )}
          </>
        )}
        <Box
          className={`${classes.detailsContainer} element-tooltip-header-details`}
        >
          <Box className={classes.elementNameContainer}>
            <Typography
              className={`${classes.elementName} element-tooltip-header-element-name`}
              sx={
                !!themeConfig?.typography?.listingHeader
                  ? themeConfig.typography.listingHeader
                  : theme.typography.listingHeader
              }
            >
              {primerVersion.name}
            </Typography>
          </Box>
          {!!primerVersion.traits && !!configData && (
            <NexusTraits
              traits={traitNames}
              traitColors={traitColors}
              configData={{
                primary: {
                  font: !!configData.primary.font
                    ? configData.primary.font
                    : !!configData.theme
                    ? configData?.theme?.typography?.h4?.fontFamily
                    : theme.typography.h4.fontFamily,
                  color: '#FDFDFD',
                },
                nexusId: configData.nexus_id ?? nexusId,
              }}
            />
          )}
        </Box>
        <Box
          className={`${classes.rightContainer} element-tooltip-header-right-container`}
        >
          <Box
            className={`${classes.elementTagContainer} element-tooltip-header-tag-container`}
            sx={{ mr: '0.5rem' }}
          >
            <Typography
              className={`${
                classes.elementTag
              } element-tooltip-tag-title element-tooltip-tag-title-${primerVersion.primer.category
                ?.replaceAll(' ', '-')
                .toLowerCase()}`}
              sx={
                !!themeConfig?.typography?.tag
                  ? themeConfig.typography.tag
                  : theme.typography.tag
              }
            >
              {primerVersion.primer.category}
            </Typography>
            <Typography
              className={`${classes.elementTagSystemName} element-tooltip-tag-system`}
              sx={
                !!themeConfig?.typography?.tag
                  ? themeConfig.typography.tag
                  : theme.typography.tag
              }
            >
              {primerVersion.primer.nexus.game_type.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const PrimerToolTipHeaderBase = PrimerToolTipHeaderBaseComp;
