import { ElementToolTipDataPrimerVersion } from 'Graphql/types';
import React from 'react';
import { PrimerToolTipHeaderVampire } from '../PrimerToolTipHeaderVampire';
import { PrimerToolTipHeaderMarvel } from '../PrimerToolTipHeaderMarvel';
import { PrimerToolTipHeaderBase } from '../PrimerToolTipHeaderBase';
import { NexusTheme } from '../../../../theme/nexusTheme';
import { ThemeProvider } from '@mui/material/styles';

interface IElementToolTipElementDisplayVersionHeaderProps {
  primerVersion: ElementToolTipDataPrimerVersion;
}

const PrimerToolTipHeaderComp = ({
  primerVersion,
}: IElementToolTipElementDisplayVersionHeaderProps) => {
  const nexusSlug = primerVersion.primer.nexus.slug;

  switch (nexusSlug) {
    case 'marvelrpg': {
      return <PrimerToolTipHeaderMarvel primerVersion={primerVersion} />;
    }

    case 'vampire': {
      return <PrimerToolTipHeaderVampire primerVersion={primerVersion} />;
    }

    default: {
      return (
        <ThemeProvider theme={NexusTheme}>
          <PrimerToolTipHeaderBase primerVersion={primerVersion} />
        </ThemeProvider>
      );
    }
  }
};

export const PrimerToolTipHeader = PrimerToolTipHeaderComp;
