import { TopBottomWithDividerCard } from 'Components/Cards/TopBottomWithDividerCard';
import React, { ReactNode } from 'react';

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

const getValue = (row: any, mapping: any, convertToSnakeCase: boolean) => {
  let value = undefined;
  if (!!mapping) {
    if (mapping.source === 'lookup') {
      value =
        row[
          convertToSnakeCase ? camelToSnakeCase(mapping.value) : mapping.value
        ];
    }

    if (mapping.source === 'value') {
      value = mapping.value;
    }
  }

  return value;
};

export const mapResultToCard = (
  row: any,
  listingConfig: any,
  convertToSnakeCase: boolean,
  index: number
): ReactNode => {
  const { mapping } = listingConfig.cardView;
  const containerClasses: string[] = [];
  if (!!row['domain']) {
    containerClasses.push(row['domain']);
  }
  if (!!row['class']) {
    containerClasses.push(row['class']);
  }
  return (
    <TopBottomWithDividerCard
      key={`${listingConfig.slug}-${index}`}
      imageUrl={row['elementImage']}
      topLeftBadge={getValue(row, mapping.topLeftBadge, convertToSnakeCase)}
      topLeftBadgeClassOnly={mapping.topLeftBadge?.classOnly ?? false}
      topRightBadge={getValue(row, mapping.topRightBadge, convertToSnakeCase)}
      bottomLeftBadge={getValue(
        row,
        mapping.bottomLeftBadge,
        convertToSnakeCase
      )}
      bottomRightBadge={getValue(
        row,
        mapping.bottomRightBadge,
        convertToSnakeCase
      )}
      dividerText={getValue(row, mapping.dividerText, convertToSnakeCase)}
      title={getValue(row, mapping.title, convertToSnakeCase)}
      titleBadge={getValue(row, mapping.titleBadge, convertToSnakeCase)}
      subtitle={getValue(row, mapping.subtitle, convertToSnakeCase)}
      content={getValue(row, mapping.content, convertToSnakeCase)}
      categorySlug={listingConfig.slug}
      containerClasses={containerClasses}
    />
  );
};
