import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface IStyleProps {
  elementTagColor?: string;
  type: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: 'calc(0rem + env(safe-area-inset-top, 0))',
    marginBottom: '0rem',
    display: 'flex',
    flexDirection: 'column',
    //height: 'calc(6.5rem + env(safe-area-inset-top, 0))',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
  },
  elementName: {
    fontFamily: 'goodOTCondBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '24px',
    color: (props: IStyleProps) =>
      props.type === 'drawer' ? '#FFFFFF' : theme.palette.secondary.main,
    textTransform: 'uppercase',
    width: 'fit-content',
  },
  separator: {
    height: '1px',
    margin: '0 1rem',
    flexGrow: 1,
    background: '#676767',
  },
  elementTag: {
    textAlign: 'center',
    padding: '.5rem',
    background: (props: IStyleProps) =>
      !!props.elementTagColor
        ? props.elementTagColor
        : theme.palette.primary.main,
    borderRadius: '.25rem',
    fontFamily: 'goodOTCondBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  traitsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  elementThumbnail: {
    height: '2rem',
    width: '2rem',
    marginRight: '.5rem',
    borderRadius: '.25rem',
    overflow: 'hidden',
    zIndex: 'inherit',
  },
  to: {
    fontFamily: 'goodOt',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    color: '#2C2722',
  },
  activationUnit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5rem',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    zIndex: 'inherit',
  },
  topRowInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem',
  },
}));
