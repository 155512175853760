export const buildYourLegend =
  require('../../../public/img/build-your-legend.svg').default;
export const dragonParty =
  require('../../../public/img/dragon-party.png').default;
export const toParty = require('../../../public/img/to_party.jpg').default;
export const toPortal = require('../../../public/img/to_portal.jpg').default;
export const toWhisper = require('../../../public/img/to_whisper.jpg').default;
export const weveGotThis =
  require('../../../public/img/weve-got-this.jpg').default;
export const pathfinderSocietyEnabled =
  require('../../../public/img/pathfinder-society-enabled.png').default;
export const tooltipHeaderBackground =
  require('../../../public/img/tooltip_header_background.jpg').default;
export const tooltipHeaderMarvelBackground =
  require('../../../public/img/tooltip_header_marvel_background.png').default;
export const tooltipHeaderVampireBackground =
  require('../../../public/img/tooltip_header_vampire_background.png').default;
export const pathfinderSocietyLimited =
  require('../../../public/img/pfs-limited.png').default;
export const pathfinderSocietyRestricted =
  require('../../../public/img/pfs-restricted.png').default;
export const spinner = require('../../../public/img/spinner.svg').default;
