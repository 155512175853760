import React from 'react';

interface IThreeActionIconProps {
  fillColor?: string;
  height?: string;
  width?: string;
}

export const ReactionActionIcon = ({
  fillColor = '#687079',
  height = '12',
  width = '12',
}: IThreeActionIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Reaction'
    >
      <title id='reaction-icon'>Reaction</title>
      <path
        d='M-0.162109 5.62743C1.68516 2.7472 4.39137 1.14475 7.67182 0.462998C12.4207 -0.52392 16.9533 -0.0433349 20.9049 2.98034C24.9585 6.08206 24.7656 10.9438 20.6037 13.9266C18.2821 15.5905 15.6586 16.3567 12.6594 16.5897L14.7241 19.5129L3.27721 17.1057L13.4992 10.9799C13.0878 12.6027 12.718 14.0611 12.3115 15.6645C14.6936 14.8657 16.6152 13.7263 17.7098 11.5438C18.7942 9.38164 17.9818 6.82494 15.7123 5.12288C13.5563 3.50597 11.0665 2.86315 8.42767 2.84C5.693 2.816 3.11597 3.45282 0.877703 5.12811C0.517596 5.39764 0.183833 5.70237 -0.162109 5.99083V5.62743Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
    </svg>
  );
};
