import React from 'react';
import { ElementDisplayHeaderMutant } from '../ElementDisplayHeaderMutant';
import { ElementDisplayHeaderBase } from '../ElementDisplayHeaderBase';
import { ThemeProvider } from '@mui/material/styles';
import { NexusTheme } from 'src/theme/nexusTheme';

interface IElementDisplayHeaderProps {
  activationUnit?: string | undefined | null;
  tagBackgroundColor?: string | undefined | null;
  elementThumbnail?: string | undefined | null;
  activationValue?: number | undefined | null;
  activationRange?: string | undefined | null;
  maxActivationValue?: number | undefined | null;
  elementCategory?: string | undefined | null;
  elementSlug?: string | undefined | null;
  nexusSlug?: string | undefined | null;
  nexusConfigData?: string | undefined | null;
  nexusId?: string | undefined | null;
  traits?: string | undefined | null;
  name?: string | undefined | null;
  tagTitle?: string | undefined | null;
  isLegacy?: boolean;
  type: string;
}

const ElementDisplayHeaderComp = ({
  activationUnit,
  tagBackgroundColor,
  elementThumbnail,
  activationValue,
  activationRange,
  maxActivationValue,
  elementCategory,
  elementSlug,
  nexusSlug,
  nexusId,
  nexusConfigData,
  traits,
  name,
  tagTitle,
  isLegacy,
  type,
}: IElementDisplayHeaderProps) => {
  return (
    <>
      {nexusSlug === 'mutantyearzero' && (
        <ElementDisplayHeaderMutant
          tagBackgroundColor={tagBackgroundColor}
          elementCategory={elementCategory}
          elementSlug={elementSlug}
          nexusConfigData={nexusConfigData}
          traits={traits}
          name={name}
          tagTitle={tagTitle}
          type={type}
        />
      )}

      {nexusSlug !== 'mutantyearzero' && (
        <ThemeProvider theme={NexusTheme}>
          <ElementDisplayHeaderBase
            activationUnit={activationUnit}
            tagBackgroundColor={tagBackgroundColor}
            elementThumbnail={elementThumbnail}
            activationValue={activationValue}
            activationRange={activationRange}
            maxActivationValue={maxActivationValue}
            elementCategory={elementCategory}
            elementSlug={elementSlug}
            nexusSlug={nexusSlug}
            nexusConfigData={nexusConfigData}
            nexusId={nexusId}
            traits={traits}
            name={name}
            tagTitle={tagTitle}
            type={type}
            isLegacy={isLegacy}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export const ElementDisplayHeader = ElementDisplayHeaderComp;
