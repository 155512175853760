import React from 'react';

interface IThreeActionIconProps {
  fillColor?: string;
  width?: string;
  height?: string;
}

export const FreeActionIcon = ({
  fillColor = '#687079',
  height = '24',
  width = '24',
}: IThreeActionIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      arie-label='Free Action'
    >
      <title id='free-action-icon'>Free Action</title>
      <path
        d='M0 11.8533L11.9658 0L24 11.9536L11.877 24L0 11.8533ZM20.4448 11.6679C17.6984 8.93838 14.8629 6.12032 12.0635 3.33816L9.04569 6.30379L14.9685 11.9064L9.02739 17.7688L11.7398 20.5746L20.4448 11.6679ZM6.96546 9.16833L4.46866 11.5352L6.73064 13.9694L9.26272 11.5416L6.96546 9.16833Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
    </svg>
  );
};
