import React from 'react';

interface IThreeActionIconProps {
  fillColor?: string;
  width?: string;
  height?: string;
}

export const TwoActionIcon = ({
  fillColor,
  height = '24',
  width = '39',
}: IThreeActionIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 39 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-label='Two Actions'
    >
      <title id='two-action-icon'>Two Actions</title>
      <path
        d='M38.1653 11.817C35.9304 14.0271 33.6961 16.2376 31.4623 18.4487C30.14 19.7625 28.8322 21.0909 27.5823 22.3479L22.4019 17.1719L27.8828 11.7725L22.3519 6.40149C24.1286 4.61314 25.8032 2.92743 27.5422 1.17702C27.7242 1.35115 27.9645 1.57249 28.1953 1.80328C31.2654 4.87227 34.3357 7.94101 37.4062 11.0095C37.637 11.2397 37.9112 11.4265 38.1653 11.6334V11.817Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M0 11.8171C1.18066 10.6851 2.3644 9.55624 3.53945 8.41843C3.77951 8.18597 3.98695 7.91976 4.13499 7.75297L8.39286 12.0346L4.0807 16.3419L0 12.0007V11.8171Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
      <path
        d='M6.14777 5.81826L11.9965 0L24.0144 12.0323C20.1024 15.9405 16.0671 19.9719 12.0351 24L6.29836 18.2825C8.38138 16.2575 10.4762 14.221 12.5584 12.1967C10.3152 9.96475 8.22517 7.88522 6.14777 5.81826Z'
        fill={!!fillColor ? fillColor : '#800008'}
      />
    </svg>
  );
};
