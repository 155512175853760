import React, { useState, useEffect } from 'react';
import { SingleActionIcon } from 'Icons/SingleActionIcon';
import { TwoActionIcon } from 'Icons/TwoActionIcon';
import { ThreeActionIcon } from 'Icons/ThreeActionIcon';
import { ReactionActionIcon } from 'Icons/ReactionActionIcon';
import { FreeActionIcon } from 'Icons/FreeActionIcon';
import Image from 'next/legacy/image';
import { useStyles } from './styles';
import { NexusTraits } from 'Components/NexusTraits';
import { useUserEntitlementsQuery } from 'Graphql/user-entitlements.query.generated';
import { LockedIcon } from 'Icons/LockedIcon';
import { ElementToolTipDataElementDisplayVersion } from 'Graphql/types';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useCurrentUser } from 'Hooks/CurrentUser';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
const legacyIcon = require('../../../../../public/img/legacyIcon.png');

interface IElementToolTipHeaderBaseProps {
  element: ElementToolTipDataElementDisplayVersion;
}

const ElementToolTipHeaderBaseComp = ({
  element,
}: IElementToolTipHeaderBaseProps) => {
  const theme = useTheme();

  const { currentUser } = useCurrentUser();
  const classes = useStyles({
    elementTagColor: element.tag_background_color ?? undefined,
    elementThumbnail: `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${element.element_thumbnail}`,
  });
  const [showActivation, setShowActivation] = useState(false);
  const [traitNames, setTraitNames] = useState<any[]>([]);
  const [traitColors, setTraitColors] = useState<any>();
  const [configData, setConfigData] = useState<any>();
  const [themeConfig, setThemeConfig] = useState<any>();
  const [nexusId, setNexusId] = useState<string>('');

  useEffect(() => {
    if (!!element && !!element.elementDisplayByElementDisplayId.nexus) {
      setConfigData(element.elementDisplayByElementDisplayId.nexus.config_data);

      if (!!configData && !!configData.theme) {
        setThemeConfig(configData.theme);
      }
      setNexusId(element.elementDisplayByElementDisplayId.nexus.id);
    }

    let traitsArray: string[] = [];
    if (!!element.traits) {
      traitsArray = element.traits.split(',');
    }
    let names: string[] = [];
    let colors: { [key: string]: string } = {};
    let items: string[] = [];

    traitsArray.forEach((trait) => {
      items = trait.split('|');
      names.push(items[0]);
      colors[items[0]] = items[1];
    });

    setTraitNames(names);
    setTraitColors(colors);
  }, [element, element.traits, configData]);

  const elementDisplayId = element.element_display_id;

  const [entitlementResult] = useUserEntitlementsQuery({
    variables: {
      category: element.elementDisplayByElementDisplayId.category + '_display',
      ids: [elementDisplayId ?? ''],
    },
    pause: !currentUser || !elementDisplayId,
    requestPolicy: 'network-only',
  });

  const { data: entitlementData, fetching: entitlementDataLoading } =
    entitlementResult;

  let entitled = false;

  if (
    (!entitlementDataLoading &&
      !!entitlementData?.slsUserEntitlements?.data &&
      entitlementData.slsUserEntitlements.data.length === 1 &&
      entitlementData.slsUserEntitlements.data[0].owned.userStatus) ||
    element.elementDisplayByElementDisplayId.nexus.slug === 'pathfinder2e'
  ) {
    entitled = true;
  }

  let ActivationUnitIcon = undefined;

  if (!!element.activation_unit) {
    if (element.activation_unit === 'reaction') {
      ActivationUnitIcon = ReactionActionIcon;
    } else if (element.activation_unit === 'free-action') {
      ActivationUnitIcon = FreeActionIcon;
    } else if (element.activation_unit === 'action') {
      if (!!element.activation_value) {
        if (element.activation_value === 1) {
          ActivationUnitIcon = SingleActionIcon;
        } else if (element.activation_value === 2) {
          ActivationUnitIcon = TwoActionIcon;
        } else if (element.activation_value === 3) {
          ActivationUnitIcon = ThreeActionIcon;
        }
      }
    }
  }

  let MaxActivationUnitIcon = undefined;

  if (!!element.activation_range) {
    if (element.activation_unit === 'action') {
      if (!!element.max_activation_value) {
        if (element.max_activation_value === 1) {
          MaxActivationUnitIcon = SingleActionIcon;
        } else if (element.max_activation_value === 2) {
          MaxActivationUnitIcon = TwoActionIcon;
        } else if (element.max_activation_value === 3) {
          MaxActivationUnitIcon = ThreeActionIcon;
        }
      }
    }
  }

  useEffect(() => {
    if (
      element.elementDisplayByElementDisplayId.nexus.slug === 'pathfinder2e'
    ) {
      if (element.elementDisplayByElementDisplayId.category === 'feat') {
        setShowActivation(true);
      }
      if (
        element.elementDisplayByElementDisplayId.category === 'spell' &&
        !element.is_legacy
      ) {
        if (element.elementDisplayByElementDisplayId.slug?.includes('-rm')) {
          setShowActivation(true);
        }
      }
    }
  }, [
    element.elementDisplayByElementDisplayId.category,
    element.elementDisplayByElementDisplayId.nexus.slug,
    element.elementDisplayByElementDisplayId.slug,
    element.is_legacy,
  ]);

  const elementThumbnailImage = !!element.element_thumbnail
    ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${element.element_thumbnail}`
    : undefined;

  // if (entitlementDataLoading) {
  //   return null;
  // }

  return (
    <Box
      className={`${classes.header} element-tooltip-header-outer`}
      sx={{
        background: !!themeConfig
          ? themeConfig.palette.base.base3
          : theme.palette.base.base3,
      }}
    >
      <Box
        className={`${classes.headerBackground} element-tooltp-header-background`}
      ></Box>
      <Box className={`${classes.headerContent} element-tooltip-header-inner`}>
        {elementThumbnailImage && (
          <>
            {entitled ? (
              <Box className={classes.elementThumbnail}>
                <Image
                  loader={imgOpLoader}
                  height={60}
                  width={60}
                  src={elementThumbnailImage}
                  alt={element.name}
                  layout='fixed'
                  quality={100}
                  unoptimized={true}
                />
              </Box>
            ) : (
              <>
                <Box className={classes.lockIcon}>
                  <LockedIcon width='24' height='24' fill='#FDFDFD' />
                </Box>
                <Box className={classes.lockedElementThumbnail}>
                  <Image
                    loader={imgOpLoader}
                    height={60}
                    width={60}
                    src={elementThumbnailImage}
                    alt={element.name}
                    layout='fixed'
                    quality={100}
                  />
                </Box>
              </>
            )}
          </>
        )}
        <Box
          className={`${classes.detailsContainer} element-tooltip-header-details`}
        >
          <Box className={classes.elementNameContainer}>
            <Box>
              <Typography
                className={`${classes.elementName} element-tooltip-header-element-name`}
                sx={
                  !!themeConfig?.typography?.listingHeader
                    ? themeConfig.typography.listingHeader
                    : theme.typography.listingHeader
                }
              >
                {element.name}
              </Typography>
            </Box>
            <Box>
              {element.is_legacy && (
                <Box
                  className='element-tooltip-header-legacy-icon'
                  sx={{ display: 'flex', alignItems: 'center', ml: '8px' }}
                >
                  <Image
                    loader={imgOpLoader}
                    src={legacyIcon}
                    alt='Legacy Icon'
                    width={16}
                    height={16}
                  />
                </Box>
              )}
            </Box>

            {!!ActivationUnitIcon &&
              !MaxActivationUnitIcon &&
              showActivation && (
                <Box className={classes.activationUnit}>
                  <ActivationUnitIcon />
                </Box>
              )}
            {!!ActivationUnitIcon &&
              !!MaxActivationUnitIcon &&
              showActivation && (
                <>
                  <Box className={classes.activationUnit}>
                    <ActivationUnitIcon />
                  </Box>
                  <Box className={classes.to}>to</Box>
                  <Box className={classes.activationUnit}>
                    <MaxActivationUnitIcon />
                  </Box>
                </>
              )}
          </Box>
          {!!element.traits && !!configData && (
            <NexusTraits
              traits={traitNames}
              traitColors={traitColors}
              configData={{
                primary: {
                  font: !!configData.primary.font
                    ? configData.primary.font
                    : !!configData.theme
                    ? configData?.theme?.typography?.h4?.fontFamily
                    : theme.typography.h4.fontFamily,
                  color: '#FDFDFD',
                },
                nexusId: nexusId,
              }}
            />
          )}
        </Box>
        <Box
          className={`${classes.rightContainer} element-tooltip-header-right-container`}
        >
          <Box
            className={`${classes.elementTagContainer} element-tooltip-header-tag-container`}
            sx={{ mr: '0.25rem' }}
          >
            <Typography
              className={`${
                classes.elementTag
              } element-tooltip-tag-title element-tooltip-tag-title-${element.tag_title
                ?.replaceAll(' ', '-')
                .toLowerCase()}`}
              sx={
                !!themeConfig?.typography?.tag
                  ? themeConfig.typography.tag
                  : theme.typography.tag
              }
            >
              {element.tag_title}
            </Typography>
            <Typography
              className={`${classes.elementTagSystemName} element-tooltip-tag-system`}
              sx={
                !!themeConfig?.typography?.tag
                  ? themeConfig.typography.tag
                  : theme.typography.tag
              }
            >
              {element.game_type_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ElementToolTipHeaderBase = ElementToolTipHeaderBaseComp;
