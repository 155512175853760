import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStyles } from './styles';
import { Button, useMediaQuery } from '@mui/material';
import { LockedIcon } from 'Icons/LockedIcon';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useElementElementDisplayProductMetaDescriptionQuery } from 'Graphql/element-element-display-product-meta-description.query.generated';
import { NexusTheme } from '../../theme/nexusTheme';
interface IElementPurchaseOptionsProps {
  primarySourceSku: string;
  primarySourceSlug: string;
  primarySourceName: string;
  nexusAssetSlug: string;
  nexusSlug: string;
  nexusName: string;
  entitled: boolean;
  container?: string;
  nexusConfig?: any;
  primarySourceNexusAssetSlug: string;
  elementInDrawer?: boolean;
}

const coverImageUrl = (nexusAssetSlug: string, sourceSlug: string) => {
  return `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}/compendium/${nexusAssetSlug}/${sourceSlug}/cover.jpg`;
};

const ElementPurchaseOptionsComp = ({
  primarySourceSku,
  primarySourceSlug,
  primarySourceName,
  nexusSlug,
  nexusName,
  entitled,
  container,
  nexusConfig,
  primarySourceNexusAssetSlug,
  elementInDrawer
}: IElementPurchaseOptionsProps) => {
  const router = useRouter();
  const matchesWidth = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const [configTheme, setConfigTheme] = useState<any>();

  useEffect(() => {
    if (!!nexusConfig && !!nexusConfig.theme) {
      setConfigTheme(nexusConfig.theme.palette);
    }
  }, [nexusConfig, nexusConfig?.theme]);

  const classes = useStyles({
    desktop: matchesWidth,
    backgroundImage: coverImageUrl(
      primarySourceNexusAssetSlug,
      primarySourceSlug
    ),
  });

  const [metaDescriptionResult] =
    useElementElementDisplayProductMetaDescriptionQuery({
      variables: {
        sourceSku: primarySourceSku,
      },
    });

  const product = metaDescriptionResult.data?.demiplane_product[0];
  const elementDisplayVersionMetaDescription = product?.meta_description;

  const onButtonClick = () => {
    router.push(`/nexus/${nexusSlug}/sources/${primarySourceSlug}`);
  };

  const UnlockButton = () => {
    return (
      <Button
        className={`${classes.button} tooltip-sidebar-unlock-unlock-btn`}
        onClick={onButtonClick}
        data-cy='element-purchase-options-unlock-btn'
        sx={{
          borderRadius: '8px',
          color: !!configTheme
            ? configTheme.common.white
            : theme.palette.common.white,
          backgroundColor: !!configTheme
            ? configTheme.primary.main
            : theme.palette.primary.main,
        }}
      >
        Unlock Options
      </Button>
    );
  };

  return (
    <>
      <ThemeProvider theme={NexusTheme}>
        {!entitled && !!product && !!product.meta_description && (
          <Box
            className={`${classes.container} ${container}-unlock-container ${elementInDrawer ? classes.contentInColumn : ''}`}
            data-cy='element-purchase-options-unlock-container'
            sx={{ background: theme.palette.base.base2 }}
          >
            {!!matchesWidth && <Box className={`${classes.sourceImage} ${elementInDrawer ? classes.sourceImageInColumn : ''}`}></Box>}
            <Box className={classes.contentCenter}>
              <Box className={`${classes.contentTop} ${elementInDrawer ? classes.contentInColumn : ''}`}>
                <Box className={classes.lockedIcon}>
                  <LockedIcon height='20' width='20' fill={'#393636'} />
                </Box>
                <Typography
                  className={`${classes.title} ${container}-unlock-title`}
                  variant='h1'
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    left: 0,
                    flexGrow: 1,
                  }}
                >
                  {`${nexusName} ${primarySourceName}`}
                </Typography>
                {!!matchesWidth && (
                  <Box className={classes.buttonWideContainer}>
                    <UnlockButton />
                  </Box>
                )}
              </Box>
              {!matchesWidth && (
                <Box className={classes.buttonNarrowContainer}>
                  <UnlockButton />
                </Box>
              )}
              <Typography
                className={`${classes.metaDescription} ${container}-unlock-desc`}
                variant='body1'
              >
                {elementDisplayVersionMetaDescription}
              </Typography>
            </Box>
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};

export const ElementPurchaseOptions = ElementPurchaseOptionsComp;
