import React from 'react';

interface LockedIconProps {
  height: string;
  width: string;
  fill?: string;
}

export const LockedIcon = ({ height, width, fill }: LockedIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.83325 0.666504C6.1764 0.666504 4.83325 2.00965 4.83325 3.6665V6.6665H6.83325V3.6665C6.83325 3.11422 7.28097 2.6665 7.83325 2.6665H12.1666C12.7189 2.6665 13.1666 3.11422 13.1666 3.6665V6.6665H15.1666V3.6665C15.1666 2.00965 13.8234 0.666504 12.1666 0.666504H7.83325ZM4.33325 7.36826C3.78097 7.36826 3.33325 7.81597 3.33325 8.36826V16.8946C3.33325 17.4469 3.78097 17.8946 4.33325 17.8946H15.6666C16.2189 17.8946 16.6666 17.4469 16.6666 16.8946V8.36826C16.6666 7.81597 16.2189 7.36826 15.6666 7.36826H4.33325Z'
        fill={fill ?? '#3276B5'}
      />
    </svg>
  );
};
