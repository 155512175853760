import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '500px',
  padding: theme.spacing(1),
}));

// Define styled components
export const CardFrame = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const TopContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  position: 'relative',
}));

export const BottomContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '2rem',
}));

export const CardDivider = styled(Box)(() => ({}));

export const Badge = styled(Box)({
  position: 'absolute',
  padding: '5px',
});

export const TopLeftBadge = styled(Badge)({
  top: 0,
  left: 0,
  transform: 'translate(-50%, -50%)',
});

export const TopRightBadge = styled(Badge)({
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
});

export const BottomLeftBadge = styled(Badge)({
  bottom: 0,
  left: 0,
  transform: 'translate(-50%, 50%)',
});

export const BottomRightBadge = styled(Badge)({
  bottom: 0,
  right: 0,
  transform: 'translate(50%, 50%)',
});
