import React from 'react';
import { OperationResult, useClient } from 'urql';
import {
  ElementElementDisplayVersionDocument,
  ElementElementDisplayVersionQuery,
} from 'Graphql/element-element-display-version.query.generated';
import { ElementDisplayContext } from '../contexts/ElementDisplayContext';
import {
  IsElementDisplayEntitledElementDisplaySourceDocument,
  IsElementDisplayEntitledElementDisplaySourceQuery,
} from 'Graphql/is-element-display-entitled-element-display-source.query.generated';
import { UserEntitlementsOutputData } from 'Graphql/types';
import {
  UserEntitlementsDocument,
  UserEntitlementsQuery,
} from 'Graphql/user-entitlements.query.generated';
import {
  IsProductKeyWaveEntitledDocument,
  IsProductKeyWaveEntitledQuery,
} from 'Graphql/is-product-key-wave-entitled.query.generated';

export const ElementDisplayProvider = React.memo(({ ...props }) => {
  const client = useClient();

  const getElementDisplayVersion = async (
    slug: string,
    category: string,
    nexusSlug: string
  ) => {
    const result: OperationResult<ElementElementDisplayVersionQuery> =
      await client
        .query(
          ElementElementDisplayVersionDocument,
          { slug, category, nexusSlug },
          {
            requestPolicy: 'cache-and-network',
          }
        )
        .toPromise();

    if (!!result?.error) {
      console.log('getElementDisplayVersion Error:', result.error);
    }
    if (
      !!result?.data?.demiplane_element_display_version &&
      result.data.demiplane_element_display_version.length > 0
    ) {
      return result.data.demiplane_element_display_version[0];
    }
    return undefined;
  };

  const isElementDisplayEntitled = async (
    elementDisplayId: string,
    entitlement?: UserEntitlementsOutputData | undefined,
    disableFreeCheck = false
  ) => {
    let entitled = false;
    let _entitlement: UserEntitlementsOutputData | undefined;

    if (!!entitlement) {
      _entitlement = entitlement;
    } else {
      const result: OperationResult<UserEntitlementsQuery> = await client
        .query(UserEntitlementsDocument, {
          elementDisplayId,
        })
        .toPromise();

      if (!!result.error) {
        console.log('isElementDisplayEntitled Error:', result.error);
        return entitled;
      }

      _entitlement = result.data?.slsUserEntitlements?.data[0];
    }

    const edsResult: OperationResult<IsElementDisplayEntitledElementDisplaySourceQuery> =
      await client
        .query(IsElementDisplayEntitledElementDisplaySourceDocument, {
          elementDisplayId,
        })
        .toPromise();

    if (!!edsResult.error) {
      console.log(`isElementDisplayEntitled Error: ${edsResult.error}`);
      return entitled;
    }

    if (!!edsResult.data?.demiplane_element_display_source[0]) {
      if (
        edsResult.data.demiplane_element_display_source.some(
          (eds) =>
            eds.source.source_state.name === 'ACTIVE' ||
            eds.source.source_state.name === 'ARCHIVED'
        ) &&
        ((!!_entitlement?.owned.userStatus && !disableFreeCheck) ||
          (!disableFreeCheck && _entitlement?.isFree))
      ) {
        entitled = true;
      } else if (
        edsResult.data.demiplane_element_display_source.some(
          (eds) => eds.source.source_state.name === 'PREORDER'
        ) &&
        !!_entitlement?.owned.userStatus
      ) {
        const preorderElementDisplaySources =
          edsResult.data.demiplane_element_display_source.filter(
            (eds) => eds.source.source_state.name === 'PREORDER'
          );

        const sourceIds = preorderElementDisplaySources.map((peds) => {
          return peds.source.id;
        });

        const isWaveEntitledResult: OperationResult<IsProductKeyWaveEntitledQuery> =
          await client
            .query(IsProductKeyWaveEntitledDocument, {
              sourceIds,
              earlyAccessEnabled: true,
              preorderAccessEnabled: false,
            })
            .toPromise();

        if (!!isWaveEntitledResult.error) {
          console.log(isWaveEntitledResult.error);
        }

        return !!isWaveEntitledResult.data?.isProductKeyWaveEntitled?.data
          ?.entitled;
      } else {
        const preorderElementDisplaySources =
          edsResult.data.demiplane_element_display_source.filter(
            (eds) => eds.source.source_state.name === 'PREORDER'
          );
        const earlyAccessElementDisplaySources =
          edsResult.data.demiplane_element_display_source.filter(
            (eds) => eds.source.source_state.name === 'EARLY_ACCESS'
          );

        if (
          preorderElementDisplaySources.length > 0 &&
          !!_entitlement?.preorder.userStatus
        ) {
          for (const eds of preorderElementDisplaySources) {
            for (const sourceWave of eds.source.source_waves) {
              if (sourceWave.preorder_access_enabled) {
                return true;
              }
            }
          }
        } else if (
          earlyAccessElementDisplaySources.length > 0 &&
          !!_entitlement?.earlyAccess.userStatus
        ) {
          const sourceIds = earlyAccessElementDisplaySources.map((eds) => {
            return eds.source.id;
          });

          const isWaveEntitledResult: OperationResult<IsProductKeyWaveEntitledQuery> =
            await client
              .query(IsProductKeyWaveEntitledDocument, {
                sourceIds,
                earlyAccessEnabled: true,
                preorderAccessEnabled: false,
              })
              .toPromise();

          if (!!isWaveEntitledResult.error) {
            console.log(isWaveEntitledResult.error);
          }

          return !!isWaveEntitledResult.data?.isProductKeyWaveEntitled?.data
            ?.entitled;
        }
      }
    }
    return entitled;
  };

  return (
    <ElementDisplayContext.Provider
      value={{
        getElementDisplayVersion,
        isElementDisplayEntitled,
      }}
      {...props}
    />
  );
});

ElementDisplayProvider.displayName = 'ElementDisplayProvider';
