import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IsElementDisplayEntitledElementDisplaySourceQueryVariables = Types.Exact<{
  elementDisplayId: Types.Scalars['bigint']['input'];
}>;


export type IsElementDisplayEntitledElementDisplaySourceQuery = { __typename?: 'query_root', demiplane_element_display_source: Array<{ __typename?: 'demiplane_element_display_source', source: { __typename?: 'demiplane_source', id: string, source_state: { __typename?: 'demiplane_source_state', name: string }, source_waves: Array<{ __typename?: 'demiplane_source_wave', early_access_enabled: boolean, preorder_access_enabled: boolean, wave_number: string }> } }> };


export const IsElementDisplayEntitledElementDisplaySourceDocument = gql`
    query isElementDisplayEntitledElementDisplaySource($elementDisplayId: bigint!) {
  demiplane_element_display_source(
    where: {element_display_id: {_eq: $elementDisplayId}}
  ) {
    source {
      id
      source_state {
        name
      }
      source_waves(where: {wave_date: {_lte: "now()"}}) {
        early_access_enabled
        preorder_access_enabled
        wave_number
      }
    }
  }
}
    `;

export function useIsElementDisplayEntitledElementDisplaySourceQuery(options: Omit<Urql.UseQueryArgs<IsElementDisplayEntitledElementDisplaySourceQueryVariables>, 'query'>) {
  return Urql.useQuery<IsElementDisplayEntitledElementDisplaySourceQuery, IsElementDisplayEntitledElementDisplaySourceQueryVariables>({ query: IsElementDisplayEntitledElementDisplaySourceDocument, ...options });
};