import { ElementElementDisplayVersionFieldsFragment } from 'Graphql/element-element_display-version.fragment.generated';
import { UserEntitlementsOutputData } from 'Graphql/types';
import { createContext } from 'react';

interface IElementDisplayContext {
  getElementDisplayVersion: (
    slug: string,
    category: string,
    nexusSlug: string
  ) => Promise<ElementElementDisplayVersionFieldsFragment | undefined>;
  isElementDisplayEntitled: (
    elementDisplayId: string,
    entitlement?: UserEntitlementsOutputData,
    disableFreeCheck?: boolean
  ) => Promise<boolean>;
}

const initialState: IElementDisplayContext = {
  getElementDisplayVersion: () =>
    new Promise<ElementElementDisplayVersionFieldsFragment | undefined>(
      (resolve) => resolve(undefined)
    ),
  isElementDisplayEntitled: () =>
    new Promise<boolean>((resolve) => resolve(false)),
};

export const ElementDisplayContext =
  createContext<IElementDisplayContext>(initialState);
