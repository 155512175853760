import React, { useState, useEffect } from 'react';
import Image from 'next/legacy/image';
import { useStyles } from './styles';
import { NexusTraits } from 'Components/NexusTraits';
import { SingleActionIcon } from 'Icons/SingleActionIcon';
import { TwoActionIcon } from 'Icons/TwoActionIcon';
import { ThreeActionIcon } from 'Icons/ThreeActionIcon';
import { ReactionActionIcon } from 'Icons/ReactionActionIcon';
import { FreeActionIcon } from 'Icons/FreeActionIcon';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { imgOpLoader } from '@demiplane-dev/ui-components/src/image-optimization-loader';
const legacyIcon = require('../../../public/img/legacyIcon.png');

interface IElementDisplayHeaderBaseProps {
  type: string;
  activationUnit?: string | undefined | null;
  tagBackgroundColor?: string | undefined | null;
  elementThumbnail?: string | undefined | null;
  activationValue?: number | undefined | null;
  activationRange?: string | undefined | null;
  maxActivationValue?: number | undefined | null;
  elementCategory?: string | undefined | null;
  elementSlug?: string | undefined | null;
  nexusSlug?: string | undefined | null;
  nexusId?: string | undefined | null;
  nexusConfigData?: any;
  traits?: string | undefined | null;
  name?: string | undefined | null;
  tagTitle?: string | undefined | null;
  isLegacy?: boolean;
}

const ElementDisplayHeaderBaseComp = ({
  activationUnit,
  tagBackgroundColor,
  elementThumbnail,
  activationValue,
  activationRange,
  maxActivationValue,
  elementCategory,
  elementSlug,
  nexusSlug,
  nexusConfigData,
  nexusId,
  traits,
  name,
  tagTitle,
  type,
  isLegacy,
}: IElementDisplayHeaderBaseProps) => {
  const classes = useStyles({
    elementTagColor: tagBackgroundColor ?? undefined,
    type,
  });
  const theme = useTheme();
  const [traitNames, setTraitNames] = useState<any[]>([]);
  const [traitColors, setTraitColors] = useState<any>();
  const [showActivation, setShowActivation] = useState(false);

  const elementThumbnailImage = !!elementThumbnail
    ? `${process.env.NEXT_PUBLIC_CONTENT_ENDPOINT_TRANSFORMED}${elementThumbnail}`
    : undefined;

  let ActivationUnitIcon = undefined;

  if (!!activationUnit) {
    if (activationUnit === 'reaction') {
      ActivationUnitIcon = ReactionActionIcon;
    } else if (activationUnit === 'free-action') {
      ActivationUnitIcon = FreeActionIcon;
    } else if (activationUnit === 'action') {
      if (!!activationValue) {
        if (activationValue === 1) {
          ActivationUnitIcon = SingleActionIcon;
        } else if (activationValue === 2) {
          ActivationUnitIcon = TwoActionIcon;
        } else if (activationValue === 3) {
          ActivationUnitIcon = ThreeActionIcon;
        }
      }
    }
  }

  let MaxActivationUnitIcon = undefined;

  if (!!activationRange) {
    if (activationUnit === 'action') {
      if (!!maxActivationValue) {
        if (maxActivationValue === 1) {
          MaxActivationUnitIcon = SingleActionIcon;
        } else if (maxActivationValue === 2) {
          MaxActivationUnitIcon = TwoActionIcon;
        } else if (maxActivationValue === 3) {
          MaxActivationUnitIcon = ThreeActionIcon;
        }
      }
    }
  }

  useEffect(() => {
    if (
      (nexusSlug === 'pathfinder2e' || nexusSlug === 'starfinder2e') &&
      (elementCategory === 'feat' || elementCategory === 'spell')
    ) {
      setShowActivation(true);
    }
  }, [elementCategory, nexusSlug, isLegacy, elementSlug]);

  useEffect(() => {
    let traitsArray: string[] = [];
    if (!!traits) {
      traitsArray = traits.split(',');
    }
    let names: string[] = [];
    let colors: { [key: string]: string } = {};
    let items: string[] = [];

    traitsArray.forEach((trait) => {
      items = trait.split('|');
      names.push(items[0]);
      colors[items[0]] = items[1];
    });

    setTraitNames(names);
    setTraitColors(colors);
  }, [traits]);

  return (
    <Box
      className={`${classes.header} element-display-header`}
      data-cy='element-display-header'
    >
      <Box className={`${classes.topRow} element-display-header-top-row`}>
        {elementThumbnailImage &&
          (type === 'drawer' || type === 'page' || type === 'listingRow') && (
            <Box
              className={`${classes.elementThumbnail} elem-disp-header-thumb-container-${type}`}
            >
              <Image
                loader={imgOpLoader}
                height={32}
                width={32}
                src={elementThumbnailImage}
                alt={name ?? undefined}
                title={name ?? undefined}
                layout='fixed'
                quality={100}
                unoptimized={true}
                className={`elem-disp-header-thumb-img-${type}`}
              />
            </Box>
          )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            className={`${classes.elementName} elem-disp-header-name-${type}`}
            sx={
              !!nexusConfigData?.theme
                ? { ...nexusConfigData?.theme?.typography?.h1 }
                : { ...theme.typography.h1 }
            }
          >
            <h1>{name}</h1>
          </Box>
          {isLegacy && (
            <Box
              className={`elem-disp-header-name-${type}-legacy-logo`}
              sx={{ display: 'flex', alignItems: 'center', ml: '8px' }}
            >
              <Image
                loader={imgOpLoader}
                src={legacyIcon}
                alt='Legacy Icon'
                width={16}
                height={16}
              />
            </Box>
          )}
        </Box>
        {!!ActivationUnitIcon && !MaxActivationUnitIcon && showActivation && (
          <Box className={classes.activationUnit}>
            <ActivationUnitIcon />
          </Box>
        )}
        {!!ActivationUnitIcon && !!MaxActivationUnitIcon && showActivation && (
          <>
            <Box className={classes.activationUnit}>
              <ActivationUnitIcon />
            </Box>
            <Box className={classes.to}>to</Box>
            <Box className={classes.activationUnit}>
              <MaxActivationUnitIcon />
            </Box>
          </>
        )}
        <Box
          className={`${classes.separator} element-display-header-bottom-separator`}
        ></Box>
        <Box
          className={`${classes.elementTag} element-display-header-tag element-display-header-tag-${elementCategory} element-display-header-tag-${elementSlug}`}
        >
          {!!tagTitle && nexusSlug === 'marvelrpg'
            ? elementCategory === 'profession'
              ? 'occupation'
              : elementCategory === 'trait'
              ? 'Traits & Tags'
              : tagTitle
            : tagTitle}
        </Box>
      </Box>
      <Box className={`${classes.bottomRow} element-display-header-bottom-row`}>
        <Box className={classes.traitsContainer}>
          {!!traits && !!nexusConfigData && (
            <NexusTraits
              traits={traitNames}
              traitColors={traitColors}
              configData={{
                primary: {
                  font: !!nexusConfigData.primary.font
                    ? nexusConfigData.primary.font
                    : !!nexusConfigData.theme
                    ? nexusConfigData?.theme?.typography?.h4?.fontFamily
                    : theme.typography.h4.fontFamily,
                  color: '#FDFDFD',
                },
                nexusId: nexusId ?? '',
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ElementDisplayHeaderBase = ElementDisplayHeaderBaseComp;
