import React from 'react';
import Image from 'next/image';
import {
  BottomContainer,
  BottomLeftBadge,
  BottomRightBadge,
  CardContainer,
  CardDivider,
  CardFrame,
  TitleContainer,
  TopContainer,
  TopLeftBadge,
  TopRightBadge,
} from './TopBottomWithDividerCard.styles';
import { Box, Typography } from '@mui/material';
import { parseHtml } from '@demiplane-dev/ui-components/src/Compendium/utils/custom-html-parser';
import Link from 'next/link';

interface ITopBottomWithDividerCardProps {
  imageUrl?: string;
  topLeftBadge?: string;
  topLeftBadgeClassOnly?: boolean;
  topRightBadge?: string;
  bottomLeftBadge?: string;
  bottomRightBadge?: string;
  dividerText?: string;
  title?: string;
  titleBadge?: string;
  subtitle?: string;
  content?: string;
  categorySlug: string;
  containerClasses?: string[];
}

export const TopBottomWithDividerCard = ({
  imageUrl,
  topLeftBadge,
  topLeftBadgeClassOnly,
  topRightBadge,
  bottomLeftBadge,
  bottomRightBadge,
  dividerText,
  title,
  titleBadge,
  subtitle,
  content,
  categorySlug,
  containerClasses,
}: ITopBottomWithDividerCardProps) => {
  const containerDividerText = !!dividerText
    ? `card-container-${dividerText}`
    : '';
  const topLeftBadgeText = !!topLeftBadge
    ? `card-badge-top-left-${topLeftBadge}`
    : '';
  let containerClass = '';
  containerClasses?.forEach((c: string) => {
    if (!!c && c.length > 0) {
      containerClass += `card-container-${c} `;
    }
  });
  return (
    <CardContainer
      className={`card-container card-container-${categorySlug} ${containerDividerText} ${containerClass}`}
      sx={{
        height: '500px',
        width: '300px',
        padding: '0px',
        border: '#000000 2px solid',
      }}
    >
      <CardFrame
        className={`card-frame`}
        sx={{
          width: '300px',
          height: '500px',
        }}
      >
        <TopContainer
          className={`card-top-container`}
          sx={{
            width: '300px',
            height: '200px',
          }}
        >
          {typeof topLeftBadge !== 'undefined' && (
            <TopLeftBadge
              className={`card-badge-top-left ${topLeftBadgeText}`}
              sx={{
                padding: '0',
                top: 20,
                left: 20,
              }}
            >
              {!topLeftBadgeClassOnly && (
                <Typography className={`card-badge-top-left-text`}>
                  {topLeftBadge}
                </Typography>
              )}
            </TopLeftBadge>
          )}
          {typeof topRightBadge !== 'undefined' && (
            <TopRightBadge
              className={`card-badge-top-right`}
              sx={{
                padding: '0',
                top: 20,
                right: 20,
              }}
            >
              <Typography className={`card-badge-top-right-text`}>
                {topRightBadge}
              </Typography>
            </TopRightBadge>
          )}
          {typeof bottomLeftBadge !== 'undefined' && (
            <BottomLeftBadge
              className={`card-badge-bottom-left`}
              sx={{
                padding: '0',
                bottom: 20,
                left: 20,
              }}
            >
              <Typography className={`card-badge-bottom-left-text`}>
                {bottomLeftBadge}
              </Typography>
            </BottomLeftBadge>
          )}
          {typeof bottomRightBadge !== 'undefined' && (
            <BottomRightBadge
              className={`card-badge-bottom-right`}
              sx={{
                padding: '0',
                bottom: 20,
                right: 20,
              }}
            >
              <Typography className={`card-badge-bottom-left-text`}>
                {bottomRightBadge}
              </Typography>
            </BottomRightBadge>
          )}
          {!!imageUrl && (
            <Image
              alt='card image'
              className={`card-image`}
              src={imageUrl}
              height={200}
              width={300}
            />
          )}
        </TopContainer>
        <CardDivider className={`card-divider`} sx={{ background: 'white' }}>
          <Box className={`card-divider-text`}>
            <Typography className={`card-divider-text-typography`}></Typography>
            {dividerText}
          </Box>
        </CardDivider>
        <BottomContainer
          className={`card-bottom-container`}
          sx={{ background: 'white' }}
        >
          <TitleContainer className={`card-title-container`}>
            <Box className={`card-title`}>
              <Typography className={`card-title-text`}>{title}</Typography>
            </Box>
            <Box className={`card-title-badge`}></Box>
          </TitleContainer>
          {!!titleBadge && (
            <Box className={`card-title-badge`}>
              <Typography className={`card-title-badge-text`}>
                {titleBadge}
              </Typography>
            </Box>
          )}
          {!!subtitle && (
            <Box className={`card-subtitle`}>
              <Typography className={`card-subtitle-text`}>
                {subtitle}
              </Typography>
            </Box>
          )}
          {!!content && (
            <Box className={`card-content`}>
              <Typography className={`card-content-text`}>
                {parseHtml({
                  content,
                  chunkId: '0',
                  linkComponent: Link,
                  nexusId: '0',
                  nexusSlug: 'daggerheart',
                  sourceSlug: 'playtest',
                })}
              </Typography>
            </Box>
          )}
        </BottomContainer>
      </CardFrame>
    </CardContainer>
  );
};
