import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { tooltipHeaderBackground } from 'Images';

interface IStyleProps {
  elementTagColor?: string;
  elementThumbnail?: string | null;
  activationUnitIcon?: string | null;
}

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    position: 'relative',
    height: '5rem',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '.625rem',
    height: '5rem',
    borderRadius: '.5rem .5rem 0 0',
    zIndex: 200,
  },
  headerBackground: {
    position: 'absolute',
    height: 'inherit',
    width: '100%',
    background: `linear-gradient(184.27deg, rgba(0, 0, 0, 0.8) 54.22%, rgba(0, 0, 0, 0) 105.23%), url(${tooltipHeaderBackground.src});`,
    backgroundSize: 'cover',
    backgroundPosition: 'left 10% bottom 50%',
    borderRadius: '.5rem .5rem 0 0',
    zIndex: 100,
    filter: 'saturate(0) brightness(2.5);',
  },
  elementThumbnail: {
    height: '3.75rem',
    width: '3.75rem',
    marginRight: '1rem',
    borderRadius: '.25rem',
    overflow: 'hidden',
    zIndex: 'inherit',
  },
  lockedElementThumbnail: {
    height: '3.75rem',
    width: '3.75rem',
    marginRight: '1rem',
    borderRadius: '.25rem',
    overflow: 'hidden',
    zIndex: 'inherit',
    filter: 'brightness(50%)',
  },
  lockIcon: {
    position: 'absolute',
    zIndex: 1000,
    left: '1.75rem',
    top: '1.5rem',
    filter: 'brightness(200%)',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 'inherit',
  },
  elementNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
    zIndex: 'inherit',
  },
  elementName: {
    fontFamily: 'goodOTCondBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '100%',
    color: theme.palette.common.white,
    zIndex: 'inherit',
    textTransform: 'uppercase',
  },
  activationUnit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '1.5rem',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    zIndex: 'inherit',
  },
  traitsTagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 'inherit',
  },
  traitsTag: {
    fontFamily: 'Gin',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '.02em',
    color: theme.palette.text.secondary,
    zIndex: 'inherit',
  },
  traitsSeperator: {
    width: '.25rem',
    height: '1.25rem',
    background: '#F1CE86',
    zIndex: 'inherit',
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '100%',
    color: theme.palette.common.white,
    flexGrow: 1,
    zIndex: 'inherit',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    zIndex: 'inherit',
  },
  elementTagContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '.5rem',
    zIndex: 'inherit',
  },
  elementTag: {
    padding: '.5rem',
    background: (props: IStyleProps) =>
      !!props.elementTagColor ? props.elementTagColor : '#4A4D5F',
    borderRadius: '.25rem',
    fontFamily: 'goodOTCondBold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '12px',
    color: '#F9EDFF',
    zIndex: 'inherit',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  elementTagSystemName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '.5rem',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '100%',
    color: theme.palette.common.white,
    zIndex: 'inherit',
  },
  underConstructionText: {
    fontFamily: 'goodOt',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontFeatureSettings: 'cpsp on',
    zIndex: 1000,
    marginTop: '1rem',
    marginLeft: '1rem',
  },
  to: {
    fontFamily: 'goodOt',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    color: theme.palette.common.white,
  },
}));
