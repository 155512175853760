import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface IStyleProps {
  desktop: boolean;
  maxHeight?: string;
  type: string;
  showElementImage: boolean;
  hideFooter?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  elementDisplay: {
    maxHeight: (props: IStyleProps) =>
      !!props.maxHeight ? `${props.maxHeight}px` : 'unset',
    overflow: 'hidden',
    color: theme.palette.secondary.main,
    width: '100%',
    height: 'fit-content',
  },
  elementImageContainer: {
    position: 'relative',
    height: (props: IStyleProps) => (props.desktop ? '25rem' : '18.75rem'),
    width: (props: IStyleProps) => (props.desktop ? '18.75rem' : '100%'),
    marginLeft: (props: IStyleProps) => (props.desktop ? '2rem' : 'auto'),
    marginRight: (props: IStyleProps) => (props.desktop ? '2rem' : 'auto'),
    float: (props: IStyleProps) => (props.desktop ? 'right' : 'unset'),
    marginBottom: (props: IStyleProps) => (props.showElementImage ? '6rem' : 0),
  },
  elementDetails: {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: 0,
    paddingBottom: (props: IStyleProps) =>
      !!props.hideFooter ? '1rem' : '2.5rem',
    color: (props: IStyleProps) =>
      props.type === 'drawer' ? '#FFFFFF' : '#393936',

    '& strong': {
      color: (props: IStyleProps) =>
        props.type === 'drawer' ? '#FFFFFF' : '#393936',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
    },
  },
  assocElementDisplayHeader: {
    margin: '0 1rem',
  },
  elementDetailsTooltip: {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: 0,
    marginTop: '20px',
    color: (props: IStyleProps) =>
      props.type === 'tooltip' ? '#FFFFFF' : '#393936',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    wordSpacing: 1,
    lineHeight: 1.5,

    '& strong': {
      color: (props: IStyleProps) =>
        props.type === 'tooltip' ? '#FFFFFF' : '#393936',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
    },
  },
  longDescription: {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: 0,
    marginTop: '20px',
    color: (props: IStyleProps) =>
      props.type === 'drawer' ? '#FFFFFF !important' : '#393936 !important',
  },
  longDescriptionTooltip: {
    marginLeft: '1rem',
    marginRight: '1rem',
    marginBottom: 0,
    marginTop: '20px',
    color: '#FFFFFF !important',
  },
  linkText: {
    textAlign: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: (props: IStyleProps) => (props.type === 'page' ? '24px' : '16px'),
    lineHeight: (props: IStyleProps) =>
      props.type === 'page' ? '24px' : '16px',
    alignItems: 'center',
    color: (props: IStyleProps) =>
      props.type === 'tooltip'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
    marginTop: '5px',
    marginBottom: '5px',
  },
  unentitledText: {
    textAlign: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: (props: IStyleProps) => (props.type === 'page' ? '24px' : '16px'),
    lineHeight: (props: IStyleProps) =>
      props.type === 'page' ? '24px' : '24px',
    alignItems: 'center',
    color: (props: IStyleProps) =>
      props.type === 'tooltip'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
    marginBottom: '5px',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  unentitledContainer: {
    marginTop: (props: IStyleProps) => (props.type === 'page' ? '1.5rem' : 0),
    width: '100%',
    alignItems: 'center',
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
  unentitledTextContainer: {},
  defaultText: {
    marginTop: (props: IStyleProps) => (props.type === 'page' ? '' : '13rem'),
  },
}));
