import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ManagedElementTooltip } from 'Components/ElementToolTip/ManagedElementTooltip';
import { IEventElementDisplayDrawer } from '@demiplane-dev/types';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';

interface INexusTraitsProps {
  traits: string[];
  traitColors: { [key: string]: string };
  traitCategories?: { [key: string]: string };
  traitSlugs?: { [key: string]: string };
  configData: {
    primary: {
      font: string;
      color?: string;
    };
    nexusId?: string;
  };
}

export const NexusTraits = ({
  traits,
  traitColors,
  traitSlugs,
  traitCategories,
  configData,
}: INexusTraitsProps) => {
  const rowRef = useRef<HTMLDivElement>();
  const [moreCount, setMoreCount] = useState<number>(0);
  const [currentTraits, setCurrentTraits] = useState<string[]>(traits);
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });
  const { emit } = useRxJsEmitter();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    handleResize();

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    setCurrentTraits([...traits]);
    setMoreCount(0);
  }, [dimensions, traits]);

  useEffect(() => {
    if (!!rowRef.current) {
      if (rowRef.current.scrollWidth > rowRef.current.clientWidth) {
        const newTraits = [...currentTraits];
        newTraits.pop();
        setCurrentTraits(newTraits);

        setMoreCount(moreCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let traitsRow = currentTraits.map((t: string) => (
    <Box
      key={t}
      sx={{
        display: 'flex',
        backgroundColor: '#F1CE86',
        py: '1px',
        px: '4px',
        mr: '-3px',
      }}
      className={`trait-tag-container ${traitCategories?.[t] ?? 'trait'}`}
    >
      <Box
        style={{ background: traitColors[t] }}
        sx={{
          padding: '.25rem',
          fontFamily: configData.primary.font,
          fontStyle: 'normal',
          fontWeight: 'normal',
          textTransform: 'uppercase',
          fontSize: '12px',
          lineHeight: '16px',
          color: configData.primary.color || '#FFFFFF',
          background: traitColors[t],
          whiteSpace: 'nowrap',
          letterSpacing: 0,
          px: '4px',
          pt: '4px',
          pb: '2px',
        }}
        className={`trait-tag ${traitCategories?.[t] ?? 'trait'}`}
      >
        {!!configData.nexusId ? (
          <ManagedElementTooltip
            slug={traitSlugs?.[t] ?? t.toLowerCase()}
            category={traitCategories?.[t] ?? 'trait'}
            nexusId={configData.nexusId}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                emit<IEventElementDisplayDrawer>('openElementDisplayDrawer', {
                  slug: traitSlugs?.[t] ?? t.toLowerCase(),
                  category: traitCategories?.[t] ?? 'trait',
                  nexusId: configData.nexusId ?? '',
                });
              }}
            >
              {t}
            </a>
          </ManagedElementTooltip>
        ) : (
          <>{t}</>
        )}
      </Box>
    </Box>
  ));

  if (moreCount > 0) {
    traitsRow.push(
      <Box
        sx={{
          display: 'flex',
          background: '#F1CE86',
          py: '1px',
          px: '4px',
          mr: '-3px',
        }}
        key={Math.random()}
      >
        <Box
          sx={{
            padding: '.25rem',
            fontFamily: configData.primary.font,
            fontStyle: 'normal',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
            lineHeight: '16px',
            color: configData.primary.color || '#FFFFFF',
            background: '#5e0000',
            whiteSpace: 'nowrap',
            letterSpacing: '1px',
            px: '4px',
            pt: '4px',
            pb: '2px',
          }}
          className='trait-tag more-traits'
        >
          {`+${moreCount} More`}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {traitsRow.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            maxHeight: '2rem',
            flexWrap: 'nowrap',
            width: '100%',
          }}
          ref={rowRef}
        >
          {traitsRow}
        </Box>
      )}
    </>
  );
};
