import { ElementToolTipDataElementDisplayVersion } from 'Graphql/types';
import React from 'react';
import { ElementToolTipHeaderBase } from '../ElementToolTipHeaderBase';
import { ElementToolTipHeaderMarvel } from '../ElementToolTipHeaderMarvel';
import { ElementToolTipHeaderVampire } from '../ElementToolTipHeaderVampire';
import { NexusTheme } from '../../../../theme/nexusTheme';
import { ThemeProvider } from '@mui/material/styles';

interface IElementToolTipElementDisplayVersionHeaderProps {
  element: ElementToolTipDataElementDisplayVersion;
}

const ElementToolTipHeaderComp = ({
  element,
}: IElementToolTipElementDisplayVersionHeaderProps) => {
  const nexusSlug = element.elementDisplayByElementDisplayId.nexus.slug;

  switch (nexusSlug) {
    case 'marvelrpg': {
      return <ElementToolTipHeaderMarvel element={element} />;
    }

    case 'vampire': {
      return <ElementToolTipHeaderVampire element={element} />;
    }

    default: {
      return (
        <ThemeProvider theme={NexusTheme}>
          <ElementToolTipHeaderBase element={element} />
        </ThemeProvider>
      );
    }
  }
};

export const ElementToolTipHeader = ElementToolTipHeaderComp;
