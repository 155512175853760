import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ElementElementDisplayProductMetaDescriptionQueryVariables = Types.Exact<{
  sourceSku: Types.Scalars['String']['input'];
}>;


export type ElementElementDisplayProductMetaDescriptionQuery = { __typename?: 'query_root', demiplane_product: Array<{ __typename?: 'demiplane_product', meta_description?: string | null }> };


export const ElementElementDisplayProductMetaDescriptionDocument = gql`
    query ElementElementDisplayProductMetaDescription($sourceSku: String!) {
  demiplane_product(where: {sku: {_eq: $sourceSku}}) {
    meta_description
  }
}
    `;

export function useElementElementDisplayProductMetaDescriptionQuery(options: Omit<Urql.UseQueryArgs<ElementElementDisplayProductMetaDescriptionQueryVariables>, 'query'>) {
  return Urql.useQuery<ElementElementDisplayProductMetaDescriptionQuery, ElementElementDisplayProductMetaDescriptionQueryVariables>({ query: ElementElementDisplayProductMetaDescriptionDocument, ...options });
};