import * as Types from './types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AlgoliaSearchEntitledQueryVariables = Types.Exact<{
  entitlementCategory: Types.Scalars['String']['input'];
  indexName: Types.Scalars['String']['input'];
  query: Types.Scalars['String']['input'];
  searchParameters?: Types.InputMaybe<Types.Scalars['json']['input']>;
}>;


export type AlgoliaSearchEntitledQuery = { __typename?: 'query_root', algoliaSearchEntitled?: { __typename?: 'ActionOutput', success: boolean, message?: string | null, result?: { [key: string]: any } | null, nbPages?: number | null } | null };


export const AlgoliaSearchEntitledDocument = gql`
    query algoliaSearchEntitled($entitlementCategory: String!, $indexName: String!, $query: String!, $searchParameters: json) {
  algoliaSearchEntitled(
    entitlementCategory: $entitlementCategory
    indexName: $indexName
    query: $query
    searchParameters: $searchParameters
  ) {
    success
    message
    result
    nbPages
  }
}
    `;

export function useAlgoliaSearchEntitledQuery(options: Omit<Urql.UseQueryArgs<AlgoliaSearchEntitledQueryVariables>, 'query'>) {
  return Urql.useQuery<AlgoliaSearchEntitledQuery, AlgoliaSearchEntitledQueryVariables>({ query: AlgoliaSearchEntitledDocument, ...options });
};